import { GridColDef } from "@mui/x-data-grid";
import React, { ReactNode } from "react";
import { useInvoicesQuery } from "src/api/hooks";
import { styled } from "@mui/material";
import { Chip as MuiChip } from "@mui/material";

import MeDataGrid from "src/components/Datagrid";
import { format } from "date-fns";

const SquareChip = styled(MuiChip)(({ theme, color }) => {
  return {
    borderRadius: 4,
  };
});

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    sortable: true,
    flex: 0.5,
  },
  {
    field: "invoiceNo",
    headerName: "Invoice No",
    sortable: true,
    width: 200,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: true,
    renderCell(params) {
      const enum status {
        BASIC = "BASIC",
        BASIC_WITH_DATE = "BASIC_WITH_DATE",
        BULK = "BULK",
        BULK_WITH_DATE = "BULK_WITH_DATE",
        DRAFT = "DRAFT",
        EXTENDED = "EXTENDED",
        NOT_MATCHED = "NOT_MATCHED",
      }
      const value: status = params.value;
      const StatusColorMapping: { [key in status]: ReactNode } = {
        BASIC: <SquareChip label={"Matched"} color={"success"} />,
        BASIC_WITH_DATE: <SquareChip label={"Matched"} color={"success"} />,
        BULK: <SquareChip label={"Matched"} color={"success"} />,
        BULK_WITH_DATE: <SquareChip label={"Matched"} color={"success"} />,
        EXTENDED: <SquareChip label={"Matched"} color={"success"} />,
        DRAFT: <SquareChip label={"Unmatched"} color={"error"} />,
        NOT_MATCHED: <SquareChip label={"Suggested"} color={"warning"} />,
      };
      return StatusColorMapping[value];
    },
  },
  {
    field: "invoiceDate",
    headerName: "Invoice Date",
    flex: 1,
    sortable: true,
    valueFormatter(params) {
      return format(params.value, "dd-MM-yyyy");
    },
  },

  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    type: "number",
    sortable: true,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "amountWithTax",
    headerName: "Amount With Tax",
    flex: 1,
    type: "number",
    sortable: true,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "customerName",
    headerName: "Anchor Name",
    flex: 1,
    sortable: true,
  },
  {
    field: "transaction.utr",
    headerName: "Transaction UTR",
    flex: 1,
    sortable: true,
    valueGetter(params) {
      return params.row.transaction?.utr;
    },
  },
  {
    field: "transaction.transactionDate",
    headerName: "Transaction Date",
    flex: 1,
    sortable: true,
    valueGetter(params) {
      return params.row.transaction?.transactionDate;
    },
  },
  {
    field: "transaction.amount",
    headerName: "Transaction Amount",
    flex: 1,
    sortable: true,
    headerAlign: "right",
    align: "right",
    valueGetter(params) {
      return params.row.transaction?.amount;
    },
  },
];

const InvoiceDataGrid = ({ id }: { id: number }) => {
  const { useListQuery } = useInvoicesQuery(id);

  return (
    <MeDataGrid
      columns={columns}
      useQuery={useListQuery}
      initialState={{
        sorting: { sortModel: [{ field: "id", sort: "desc" }] },
      }}
    ></MeDataGrid>
  );
};

export default InvoiceDataGrid;
