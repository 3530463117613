/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Stack,
  Button,
} from "@mui/material";
import { Upload01 } from "@untitled-ui/icons-react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

import UploadFile from "src/icons/untitled-ui/duocolor/upload-file";
interface BootstrapDropzoneInputProps {
  subTitle?: string;
  dropzoneProps: DropzoneOptions;
  loading?: boolean;
}
const BootstrapDropzoneInput = ({
  dropzoneProps,
  subTitle,
  loading,
}: BootstrapDropzoneInputProps) => {
  const { getRootProps, getInputProps } = useDropzone(dropzoneProps);
  return (
    <Box
      sx={{
        borderRadius: 1.5,
        border: "1px dashed #1E88E5",
        background: "rgba(30, 136, 229, 0.04)",
        p: 5,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      {loading ? (
        <CircularProgress />
      ) : (
        <Stack alignItems={"center"} spacing={2}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            sx={{ p: 1.5, minWidth: 0 }}
          >
            <UploadFile />
          </Button>
          <Typography component={"div"} textAlign={"center"}>
            <Typography fontWeight={600} variant={"body1"}>
              Drag and drop or{" "}
              <Typography
                color={"primary.main"}
                sx={{ cursor: "pointer" }}
                component="span"
                fontWeight={600}
              >
                Choose
              </Typography>{" "}
              file to upload
            </Typography>
            <Typography color="textSecondary" variant={"body2"}>
              {subTitle || "Supported All type of file"}
            </Typography>
          </Typography>
        </Stack>
      )}
    </Box>
  );
};
export default BootstrapDropzoneInput;
