import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Seo } from "src/components/seo";
import { useSettings } from "src/hooks/use-settings";
import { Card, CardHeader, Divider, Grid } from "@mui/material";
import { DashboardCard } from "src/sections/dashboard/overview/dashboard-card";
import InvoiceFilled from "src/icons/untitled-ui/duocolor/invoice-filled";
import Idea from "src/icons/untitled-ui/duocolor/Idea";

import Money from "src/icons/untitled-ui/duocolor/money";
import { ImportExport } from "@mui/icons-material";
import { Scrollbar } from "src/components/scrollbar";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import { useReportQuery as ReportQuery, useReportsQuery } from "src/api/hooks";
import InvoiceDataGrid from "./InvoiceDataGrid";
import { useReportExportQuery } from "src/api/hooks/use-report-export-query.hooks";
import { LoadingButton } from "@mui/lab";

const { useDetailQuery } = ReportQuery();

const Report = () => {
  const settings = useSettings();

  const { id } = useParams();
  const reportId = id && Number.isInteger(parseInt(id)) ? parseInt(id) : -1;
  const {
    getExportReport,
    loading: exportLoading,
    csvData,
  } = useReportExportQuery();

  const exportReport = async () => {
    const csvData = await getExportReport(reportId);

    const url = window.URL.createObjectURL(new Blob([csvData]));
    const link = document.createElement("a");
    link.href = url;
    const fileName = `match_invoice_report_${id}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const getTransactionPercentage = (
    matchCount: number | undefined,
    total: number | undefined
  ) => {
    if (matchCount && total) {
      return Math.round((matchCount / total) * 100);
    }
    return "-";
  };

  const { report, error, loading } = useDetailQuery(reportId);
  return (
    <>
      <Seo title="Dashboard: Blank" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <Stack
              spacing={{
                xs: 3,
                lg: 4,
              }}
            >
              <Stack direction="row" justifyContent="space-between" spacing={4}>
                <div>
                  <Typography variant="h4">Invoice Matching Report </Typography>
                  <Typography variant="body2" color={"textSecondary"}>
                    Report Generated On:{" "}
                    <b>{report?.createdAt.toDateString()}</b>
                  </Typography>
                </div>
                <div>
                  <Stack direction="row" spacing={4}></Stack>
                </div>
              </Stack>
              <Box>
                <Grid container spacing={5}>
                  <Grid container item spacing={5}>
                    <Grid item xs={12} sm={6} lg={4} display={"flex"}>
                      <DashboardCard
                        value={report?.totalInvoice || 0}
                        title="Total Invoices"
                        icon={<InvoiceFilled />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} display={"flex"}>
                      <DashboardCard
                        value={report?.invoiceStats?.MATCHED || 0}
                        title="Matched Invoices"
                        icon={<Idea />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} display={"flex"}>
                      <DashboardCard
                        value={getTransactionPercentage(
                          report?.invoiceStats?.MATCHED,
                          report?.totalInvoice
                        )}
                        title="Exact Match Percentage"
                        icon={<PercentOutlinedIcon />}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        action={
                          <LoadingButton
                            variant="contained"
                            color="inherit"
                            onClick={exportReport}
                            loading={exportLoading}
                          >
                            Export
                          </LoadingButton>
                        }
                        title={"Report ID: " + report?.id}
                      />
                      <Divider />

                      <Scrollbar>
                        <InvoiceDataGrid id={reportId} />
                      </Scrollbar>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Container>
        )}
      </Box>
    </>
  );
};

export default Report;
