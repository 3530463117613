/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";

import axios, { AxiosRequestConfig } from "axios";
import { DataGridRowsModel, Report } from "../models";
import {
  GridFilterModel,
  GridPaginationModel,
  GridRowModel,
  GridSortModel,
} from "@mui/x-data-grid";

export const useReportsQuery = () => {
  const useListQuery = (
    paginationModel?: GridPaginationModel | undefined,
    sortModel?: GridSortModel | undefined,
    filterModel?: GridFilterModel | undefined
  ) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();

    const [data, setData] = useState<DataGridRowsModel>({ count: 0, rows: [] });

    useEffect(() => {
      let controller = new AbortController();
      (async () => {
        try {
          let queryParams: {
            pageNo?: number;
            pageSize?: number;
            orderBy?: string;
            orderDir?: string;
          } = {
            pageNo: 0,
            pageSize: 20,
            orderBy: "id",
            orderDir: "desc",
          };

          if (sortModel) {
            sortModel.forEach((m) => {
              queryParams = {
                ...queryParams,
                orderBy: m.field,
                orderDir: m.sort || "desc",
              };
            });
          }

          if (paginationModel) {
            queryParams = {
              ...queryParams,
              pageNo: paginationModel.page,
              pageSize: paginationModel.pageSize,
            };
          }

          setLoading(true);
          const config: AxiosRequestConfig = {
            signal: controller.signal,
            method: "get",
            url: process.env.REACT_APP_API_URL + "/report",
            params: queryParams,
          };
          const response = await axios.request(config);
          if (response.status === 200) {
            const reports: Report[] = response.data.content.map(
              (report: any) => {
                return {
                  id: report.id,
                  createdAt: new Date(report.createdAt),
                  updatedAt: new Date(report.updatedAt),
                  status: report.status,
                };
              }
            );
            setData({ count: response.data.totalElements, rows: reports });
          } else {
            console.log("reports api failed", response.data);
            setError(new Error("something went wrong"));
          }
        } catch (e) {
          // Handle fetch error
          setError(new Error("something went wrong"));
        } finally {
          setLoading(false);
        }
      })();
      return () => controller?.abort();
    }, [paginationModel, sortModel, filterModel]);

    return { loading, data, error };
  };
  return { useListQuery };
};
