/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";

import axios, { AxiosRequestConfig } from "axios";

export const useReportMatchQuery = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState<String>("");
  const matchInvoiceMutation = async (id: number) => {
    try {
      setLoading(true);
      const config: AxiosRequestConfig = {
        method: "get",
        url: process.env.REACT_APP_API_URL + "/report/" + id + "/match",
      };
      const response = await axios.request(config);
      const status = response.data;
      setStatus(status);
      return status;
    } catch (e) {
      // Handle fetch error
      setError("something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return { matchInvoiceMutation, loading, status, error };
};
