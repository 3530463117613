import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Invoice from "src/icons/untitled-ui/duocolor/invoice";
import InvoiceFilled from "src/icons/untitled-ui/duocolor/invoice-filled";
import { Chip } from "@mui/material";

interface DashboardCardProps {
  value: number | string;
  icon: ReactNode;
  title: string;
}

export const DashboardCard: FC<DashboardCardProps> = (props) => {
  const { value, title, icon } = props;

  return (
    <Card sx={{ flexGrow: 1 }}>
      <Stack
        alignItems="center"
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={3}
        sx={{
          px: 4,
          py: 3,
        }}
      >
        <Box>
          <Box
            sx={{
              p: 1,
              borderRadius: "50%",
              backgroundColor: "primary.main",
              width: 40,
              height: 40,
              color: "white",
            }}
          >
            {icon}
          </Box>
        </Box>
        <Box
          sx={{ height: "100%" }}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
        >
          <Typography color="text.secondary" variant="body2">
            {title}
          </Typography>
          <Typography color="text.primary" variant="h4">
            {value}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};
