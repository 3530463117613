/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";

import axios, { AxiosRequestConfig } from "axios";
import { DataGridRowsModel, Invoice } from "../models";
import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";

export const useInvoicesQuery = (id: number) => {
  const useListQuery = (
    paginationModel?: GridPaginationModel | undefined,
    sortModel?: GridSortModel | undefined,
    filterModel?: GridFilterModel | undefined
  ) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();

    const [data, setData] = useState<DataGridRowsModel>({ count: 0, rows: [] });

    useEffect(() => {
      let controller = new AbortController();
      (async () => {
        try {
          let queryParams: {
            pageNo?: number;
            pageSize?: number;
            orderBy?: string;
            orderDir?: string;
          } = {
            pageNo: 0,
            pageSize: 20,
            orderBy: "id",
            orderDir: "desc",
          };

          if (sortModel) {
            sortModel.forEach((m) => {
              queryParams = {
                ...queryParams,
                orderBy: m.field,
                orderDir: m.sort || "desc",
              };
            });
          }

          if (paginationModel) {
            queryParams = {
              ...queryParams,
              pageNo: paginationModel.page,
              pageSize: paginationModel.pageSize,
            };
          }

          setLoading(true);
          const config: AxiosRequestConfig = {
            signal: controller.signal,
            method: "get",
            url: process.env.REACT_APP_API_URL + "/report/" + id + "/invoices",
            params: queryParams,
          };
          const response = await axios.request(config);
          const invoices: Invoice[] = response.data.content.map(
            (invoice: any) => ({
              id: invoice.id,
              createdAt: new Date(invoice.createdAt),
              updatedAt: new Date(invoice.updatedAt),
              status: invoice.status,
              amount: invoice.amount,
              amountWithTax: invoice.amountWithTax,
              invoiceNo: invoice.invoiceNo,
              invoiceDate: new Date(invoice.invoiceDate),
              customerName: invoice.customerName,
              transaction: invoice.transaction,
            })
          );
          console.log("invoices", invoices);
          setData({ count: response.data.totalElements, rows: invoices });
        } catch (e) {
          // Handle fetch error
          setError(new Error("something went wrong"));
        } finally {
          setLoading(false);
        }
      })();
      return () => controller?.abort();
    }, [paginationModel, sortModel, filterModel]);

    return { loading, data, error };
  };
  return { useListQuery };
};
