import { ReactNode, useState } from "react";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { Button, Link, Chip as MuiChip } from "@mui/material";

import { styled } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import MeDataGrid from "./Datagrid";
import { useReportsQuery } from "src/api/hooks";
import { DownloadOutlined } from "@mui/icons-material";
import { useReportExportQuery } from "src/api/hooks/use-report-export-query.hooks";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";

const SquareChip = styled(MuiChip)(({ theme, color }) => {
  return {
    borderRadius: 4,
  };
});

const ReportsDataGrid = () => {
  const [activeReport, setActiveReport] = useState(-1);
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      flex: 1,
      renderCell: (params) => (
        <Link component={RouterLink} to={`report/${params.row.id}`}>
          {params.row.id}
        </Link>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,

      sortingOrder: ["asc", "desc"],
      renderCell(params) {
        const enum status {
          PENDING_VERIFICATION = "IN_PROGRESS",
          PENDING = "PENDING",
          DRAFT = "DRAFT",
          COMPLETED = "COMPLETED",
          FAILED = "FAILED",
        }
        const value: status = params.value;
        const StatusColorMapping: { [key in status]: ReactNode } = {
          PENDING: <SquareChip label={"In Progress"} color={"info"} />,
          COMPLETED: <SquareChip label={"Completed"} color={"success"} />,
          IN_PROGRESS: <SquareChip label={"In Progress"} color={"info"} />,
          DRAFT: <SquareChip label={"Draft"} color={"default"} />,
          FAILED: <SquareChip label={"Failed"} color={"error"} />,
        };
        return StatusColorMapping[value];
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      type: "dateTime",
      sortable: true,

      sortingOrder: ["asc", "desc"],
      width: 200,
      flex: 1,
      valueFormatter(params) {
        return format(params.value, "dd-mm-yyyy");
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerAlign: "left",
      flex: 1,
      getActions: (params: GridRowParams) => [
        <Button component={RouterLink} to={`/report/${params.row.id}`}>
          View Details
        </Button>,
        <LoadingButton
          size="small"
          onClick={() => exportReport(params.row.id)}
          loading={activeReport === params.row.id && exportLoading}
        >
          Download
        </LoadingButton>,
      ],
    },
  ];

  const { useListQuery } = useReportsQuery();

  const {
    getExportReport,
    loading: exportLoading,
    csvData,
  } = useReportExportQuery();

  const exportReport = async (reportId: number) => {
    setActiveReport(reportId);
    const csvData = await getExportReport(reportId);

    const url = window.URL.createObjectURL(new Blob([csvData]));
    const link = document.createElement("a");
    link.href = url;
    const fileName = `match_invoice_report_${reportId}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setActiveReport(-1);
  };

  return (
    <MeDataGrid
      columns={columns}
      useQuery={useListQuery}
      initialState={{ sorting: { sortModel: [{ field: "id", sort: "desc" }] } }}
    ></MeDataGrid>
  );
};

export default ReportsDataGrid;
