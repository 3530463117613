import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Seo } from "src/components/seo";
import { useSettings } from "src/hooks/use-settings";
import {
  Card,
  CardHeader,
  Grid,
  CardContent,
  Snackbar,
  Alert,
  AlertTitle,
  useTheme,
  IconButton,
  Link,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import BootstrapDropzoneInput from "src/components/bootstrap-dropzone";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useCreateReportMutation } from "src/api/hooks";
import { useReportMatchQuery } from "src/api/hooks/use-invoice-match-query.hooks";
import { useReportStatusQuery } from "src/api/hooks/use-report-status-query.hooks";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import UploadFile from "src/icons/untitled-ui/duocolor/upload-file";
const MatchInvoice = () => {
  const settings = useSettings();
  const theme = useTheme();
  const [invoiceFile, setInvoiceFile] = useState<File | null>(null);
  const [transactionFile, setTransactionFile] = useState<File | null>(null);
  const { getReportStatus } = useReportStatusQuery();
  const { matchInvoiceMutation } = useReportMatchQuery();

  const [alertMessage, setAlertMessage] = useState<FileUploadAlertProps>();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const {
    useMutation: callApi,
    loading,
    error,
    mutationResponse,
  } = useCreateReportMutation();

  const onDropInvoice = useCallback((acceptedFiles: File[]) => {
    setInvoiceFile(acceptedFiles[0]);
  }, []);

  const onDropTransaction = useCallback((acceptedFiles: File[]) => {
    setTransactionFile(acceptedFiles[0]);
  }, []);

  const submit = async () => {
    if (invoiceFile && transactionFile) {
      const data = new FormData();
      data.append("invoice_file", invoiceFile);
      data.append("transaction_file", transactionFile);

      try {
        await callApi(data, "/report");

        const alert: FileUploadAlertProps = {
          icon: <FileCopyTwoToneIcon color="info" />,
          title: "Identifying invoices..",
          subTitle:
            "From the files uploaded, Safe Passage is identifying and matching your invoices.",
        };
        setShowAlert(true);
        setAlertMessage(alert);
        setTransactionFile(null);
        setInvoiceFile(null);
      } catch (err) {
        console.log("error", err);
      }
    } else {
      // Please upload file
    }
  };

  useEffect(() => {
    (async () => {
      if (mutationResponse) {
        const resp = await matchInvoiceMutation(mutationResponse.id);
        let count = 0;
        let reportStatus = "";
        while (count < 20 && reportStatus !== "COMPLETED") {
          reportStatus = await getReportStatus(mutationResponse.id);

          count++;
        }

        if (reportStatus === "COMPLETED") {
          const alert: FileUploadAlertProps = {
            icon: <TaskTwoToneIcon color="info" />,
            title: "Invoices Matched Successfully!",
            subTitle: (
              <Typography paragraph>
                Click{" "}
                <Link
                  to={`/report/${mutationResponse.id}`}
                  component={RouterLink}
                >
                  here
                </Link>{" "}
                to view the matched invoices.
              </Typography>
            ),
          };
          setShowAlert(false);
          setAlertMessage(alert);
          setShowAlert(true);
        }
      }
    })();
  }, [mutationResponse]);

  return (
    <>
      <Seo title="Dashboard: Create Report" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={settings.stretch ? false : "md"}>
          <Stack
            spacing={{
              xs: 3,
              lg: 4,
            }}
          >
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <div>
                <Typography variant="h4">
                  Identify and match invoices
                </Typography>
                <Typography variant="body2" color={"textSecondary"}>
                  Upload your unbilled invoices to identify and match them to
                  your borrower’s transaction data.
                </Typography>
              </div>
              <div>
                <Stack direction="row" spacing={4}></Stack>
              </div>
            </Stack>

            {showAlert && alertMessage !== undefined && (
              <Box>
                <FileUploadAlert {...alertMessage} />
              </Box>
            )}
            <Box>
              <Grid container spacing={5} justifyContent={"space-between"}>
                <Grid item xs={12} sm={6} md={6} display={"flex"}>
                  <Card sx={{ flexGrow: 1 }}>
                    <CardHeader
                      action={
                        <Button
                          component={Link}
                          href="/assets/templates/invoice.csv"
                          size="small"
                        >
                          Download Template
                        </Button>
                      }
                      title="Upload Invoice"
                    />
                    <CardContent>
                      <Box mb={2}>
                        <BootstrapDropzoneInput
                          dropzoneProps={{
                            accept: {
                              "text/plain": [".csv"],
                            },
                            multiple: false,
                            maxFiles: 1,
                            onDrop: onDropInvoice,
                          }}
                          subTitle="Supported file type csv"
                        />
                      </Box>
                      {invoiceFile !== null && (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          sx={{
                            borderRadius: 1,
                            background: "#F0F3F5",
                            px: 2.5,
                            py: 2,
                          }}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"flex-start"}
                            spacing={2}
                          >
                            <Box
                              sx={{
                                background: "white",
                                px: 1,
                                height: "40px",
                                borderRadius: 0.5,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <UploadFile color={theme.palette.primary.main} />
                            </Box>
                            <Typography
                              variant="body2"
                              fontWeight={500}
                              color={"textPrimary"}
                              sx={{ wordBreak: "break-word" }}
                            >
                              {invoiceFile?.name}
                            </Typography>
                          </Stack>
                          <IconButton
                            onClick={() => setInvoiceFile(null)}
                            size="small"
                            color="error"
                            sx={{ ml: 2 }}
                          >
                            <CloseIcon color="action" />
                          </IconButton>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={6} display={"flex"}>
                  <Card sx={{ flexGrow: 1 }}>
                    <CardHeader
                      action={
                        <Button
                          component={Link}
                          href="/assets/templates/transaction.csv"
                          size="small"
                        >
                          Download Template
                        </Button>
                      }
                      title="Upload Transactional Data"
                    />
                    <CardContent>
                      <Box mb={2}>
                        <BootstrapDropzoneInput
                          dropzoneProps={{
                            accept: {
                              "text/plain": [".csv"],
                            },
                            multiple: false,
                            maxFiles: 1,
                            onDrop: onDropTransaction,
                          }}
                          subTitle="Supported file type csv"
                        />
                      </Box>

                      {transactionFile !== null && (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          sx={{
                            borderRadius: 1,
                            background: "#F0F3F5",
                            px: 2.5,
                            py: 2,
                          }}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"flex-start"}
                            spacing={2}
                          >
                            <Box
                              sx={{
                                background: "white",
                                px: 1,
                                height: "40px",
                                borderRadius: 0.5,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <UploadFile color={theme.palette.primary.main} />
                            </Box>
                            <Typography
                              variant="body2"
                              fontWeight={500}
                              color={"textPrimary"}
                              sx={{ wordBreak: "break-word" }}
                            >
                              {transactionFile?.name}
                            </Typography>
                          </Stack>
                          <IconButton
                            onClick={() => setTransactionFile(null)}
                            size="small"
                            color="error"
                            sx={{ ml: 2 }}
                          >
                            <CloseIcon color="action" />
                          </IconButton>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    size="large"
                    loading={loading}
                    onClick={submit}
                    disabled={!(invoiceFile && transactionFile)}
                  >
                    Proceed
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

type FileUploadAlertProps = {
  icon: ReactNode;
  title: string;
  subTitle: ReactNode | string;
};
export const FileUploadAlert = (props: FileUploadAlertProps) => {
  const { icon, title, subTitle } = props;
  const theme = useTheme();

  return (
    <Alert
      severity="info"
      icon={
        <Box
          sx={{
            background: "#fff",
            padding: 1.75,
            display: "flex",
            alignItems: "center",
            borderRadius: 1,
          }}
        >
          {icon}
        </Box>
      }
      variant="filled"
      sx={{
        background: "rgba(56, 100, 255, 0.06)",
        borderRadius: 1.75,
      }}
    >
      <AlertTitle
        sx={{
          fontSize: "18px",
          fontWeight: 700,
          color: theme.palette.text.primary,
        }}
      >
        {title}
      </AlertTitle>
      <Typography variant="body2" color={"textSecondary"}>
        {subTitle}
      </Typography>
    </Alert>
  );
};

export default MatchInvoice;
