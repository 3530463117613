import axios, { AxiosRequestConfig } from "axios";
import { useState } from "react";

type MutationResponse = {
  message?: string;
  id: number;
};

export const useCreateReportMutation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mutationResponse, setMutationResponse] = useState<MutationResponse>();

  const useMutation = async (data: any, uri: string) => {
    setLoading(true);

    const config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL + uri,
      data: data,
    };

    const response = await axios.request(config);

    if (response.status !== 200) {
      // error
      setError("Something went wrong");
    } else {
      //success
      setMutationResponse({
        id: response.data.id,
      });
    }
    setLoading(false);

    return { loading, mutationResponse, error };
  };
  return { useMutation, loading, mutationResponse, error };
};
