import { ReactNode, useState } from "react";

import { Box, styled } from "@mui/material";

import { DataGridQueryResponse } from "../api/models";
import {
  GridInitialState,
  GridRowIdGetter,
  GridRowParams,
  GridCallbackDetails,
  GridSortModel,
  GridFilterModel,
  GridRowModel,
  DataGrid,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid";

interface DataGridProps {
  columns: GridColDef[];
  initialState?: GridInitialState;
  useQuery(
    paginationModel?: GridPaginationModel | undefined,
    sortModel?: GridSortModel | undefined,
    filterModel?: GridFilterModel | undefined
  ): DataGridQueryResponse<GridRowModel>;
  getDetailPanelContent?: (params: GridRowParams) => ReactNode;
  getDetailPanelHeight?: (params: GridRowParams) => number | "auto";
  getRowId?: GridRowIdGetter;
}

const DataGridStyled = styled(DataGrid)(({ theme }) => {
  return {
    ".MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
    },
  };
});

const MeDataGrid = (props: DataGridProps) => {
  const { columns, initialState, useQuery, getRowId } = props;

  const defaultPaginationModel: GridPaginationModel = {
    page: initialState?.pagination?.paginationModel?.page || 0,
    pageSize: initialState?.pagination?.paginationModel?.pageSize || 20,
  };

  const [paginationModel, setPaginationModel] = useState(
    defaultPaginationModel
  );

  const [sortModel, setSortModel] = useState<GridSortModel | undefined>(
    initialState?.sorting?.sortModel
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(
    initialState?.filter?.filterModel
  );

  const onPaginationModelChange = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel);
  };

  const onSortModelChange = (
    model: GridSortModel,
    d: GridCallbackDetails<"filter">
  ) => {
    setSortModel(model);
  };

  const onFilterModelChange = (
    model: GridFilterModel,
    d: GridCallbackDetails<"filter">
  ) => {
    setFilterModel(model);
  };

  const { loading, data, error } = useQuery(
    paginationModel,
    sortModel,
    filterModel
  );

  return (
    <Box>
      <DataGridStyled
        getRowId={getRowId}
        loading={loading}
        columns={columns}
        rows={data.rows}
        autoHeight
        paginationMode="server"
        rowCount={data.count}
        paginationModel={paginationModel}
        filterMode="server"
        onPaginationModelChange={onPaginationModelChange}
        onFilterModelChange={onFilterModelChange}
        onSortModelChange={onSortModelChange}
        initialState={initialState}
        pagination
      />
    </Box>
  );
};

export default MeDataGrid;
