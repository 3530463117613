import type { SVGProps } from "react";

const UploadFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      id="Vector"
      d="M20.5 10.8521H17.61C15.24 10.8521 13.31 8.92211 13.31 6.55211V3.66211C13.31 3.11211 12.86 2.66211 12.31 2.66211H8.07C4.99 2.66211 2.5 4.66211 2.5 8.23211V17.0921C2.5 20.6621 4.99 22.6621 8.07 22.6621H15.93C19.01 22.6621 21.5 20.6621 21.5 17.0921V11.8521C21.5 11.3021 21.05 10.8521 20.5 10.8521ZM11.53 14.1921C11.38 14.3421 11.19 14.4121 11 14.4121C10.81 14.4121 10.62 14.3421 10.47 14.1921L9.75 13.4721V17.6621C9.75 18.0721 9.41 18.4121 9 18.4121C8.59 18.4121 8.25 18.0721 8.25 17.6621V13.4721L7.53 14.1921C7.24 14.4821 6.76 14.4821 6.47 14.1921C6.18 13.9021 6.18 13.4221 6.47 13.1321L8.47 11.1321C8.54 11.0721 8.61 11.0221 8.69 10.9821C8.71 10.9721 8.74 10.9621 8.76 10.9521C8.82 10.9321 8.88 10.9221 8.95 10.9121C8.98 10.9121 9 10.9121 9.03 10.9121C9.11 10.9121 9.19 10.9321 9.27 10.9621C9.28 10.9621 9.28 10.9621 9.29 10.9621C9.37 10.9921 9.45 11.0521 9.51 11.1121C9.52 11.1221 9.53 11.1221 9.53 11.1321L11.53 13.1321C11.82 13.4221 11.82 13.9021 11.53 14.1921Z"
      fill="currentColor"
    />
    <path
      id="Vector_2"
      d="M17.4297 9.47259C18.3797 9.48259 19.6997 9.48259 20.8297 9.48259C21.3997 9.48259 21.6997 8.81259 21.2997 8.41259C19.8597 6.96259 17.2797 4.35259 15.7997 2.87259C15.3897 2.46259 14.6797 2.74259 14.6797 3.31259V6.80259C14.6797 8.26259 15.9197 9.47259 17.4297 9.47259Z"
      fill="currentColor"
    />
  </svg>
);

export default UploadFile;
