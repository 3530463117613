/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";

import axios, { AxiosRequestConfig } from "axios";
import { Report, ReportInvoiceStats, ReportTransactionStats } from "../models";

export const useReportQuery = () => {
  const useDetailQuery = (id: number) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [report, setReport] = useState<Report>();

    useEffect(() => {
      let controller = new AbortController();
      (async () => {
        try {
          setLoading(true);
          const config: AxiosRequestConfig = {
            signal: controller.signal,
            method: "get",
            url: process.env.REACT_APP_API_URL + "/report/" + id,
          };
          const response = await axios.request(config);
          const report: Report = {
            id: response.data.report.id,
            createdAt: new Date(response.data.report.createdAt),
            updatedAt: new Date(response.data.report.updatedAt),
            status: response.data.report.status,
          };

          let matchedTxnCount = 0;
          let notMatchedTxnCount = 0;
          response.data.transactionStatusCountList.forEach((type: any) => {
            if (type["status"] === "MATCHED") {
              matchedTxnCount = matchedTxnCount + type["count"];
            } else {
              notMatchedTxnCount = notMatchedTxnCount + type["count"];
            }
          });
          const transactionStatus: ReportTransactionStats = {
            MATCHED: matchedTxnCount,
            NOT_MATCHED: notMatchedTxnCount,
          };

          let matchedInvoiceCount = 0;
          let notMatchedInvoiceCount = 0;
          response.data.invoiceStatusCountList.forEach((type: any) => {
            if (
              type["status"] === "BULK" ||
              type["status"] === "BULK_WITH_DATE" ||
              type["status"] === "BASIC" ||
              type["status"] === "BASIC_WITH_DATE"
            ) {
              matchedInvoiceCount = matchedInvoiceCount + type["count"];
            } else {
              notMatchedInvoiceCount = notMatchedInvoiceCount + type["count"];
            }
          });
          const invoiceStatus: ReportInvoiceStats = {
            MATCHED: matchedInvoiceCount,
            NOT_MATCHED: notMatchedInvoiceCount,
          };
          report.totalTransaction = response.data.transactionCount;
          report.transactionStats = transactionStatus;
          report.invoiceStats = invoiceStatus;
          report.totalInvoice = response.data.invoiceCount;

          setReport(report);
        } catch (e) {
          // Handle fetch error
          setError("something went wrong");
        } finally {
          setLoading(false);
        }
      })();
      return () => controller?.abort();
    }, [id]);

    return { loading, report, error };
  };
  return { useDetailQuery };
};
