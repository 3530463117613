import { Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

import { Layout as DashboardLayout } from "src/layouts/dashboard";

import Dashboard from "src/pages/dashboard";
import Report from "src/pages/report";
import UploadInvoice from "src/pages/match-invoice";

export const routes: RouteObject[] = [
  {
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/match-invoice",
        element: <UploadInvoice />,
      },
      {
        path: "/report/:id",
        element: <Report />,
      },
    ],
  },
];
