/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";

import axios, { AxiosRequestConfig } from "axios";

export const useReportExportQuery = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [csvData, setCsvData] = useState<String>("");

  const getExportReport = async (id: number) => {
    try {
      setLoading(true);
      const config: AxiosRequestConfig = {
        method: "get",
        url: process.env.REACT_APP_API_URL + "/report/" + id + "/export",
      };
      const response = await axios.request(config);
      const csvData = response.data;

      setCsvData(csvData);
      return csvData;
    } catch (e) {
      // Handle fetch error
      setError("something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return { getExportReport, loading, csvData, error };
};
